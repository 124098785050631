

export default function(to, from, savedPosition) {
      // ignore savedPosition for specific routes [products]
      if (to.name.includes('product')) {
        return { x: 0, y: 0 };
      }

      // Check if there's a saved position to go back to
      if (savedPosition) {
        return savedPosition;
      }

      // If the route has a specific meta field, handle it differently
      if (to.matched.some((record) => record.meta.scrollToTop)) {
        return { x: 0, y: 0 };
      }

      // Default behavior
      return { x: 0, y: 0 };
    }
